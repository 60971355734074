import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import './index.scss'
import { useStaticQuery, graphql, Link } from "gatsby"
import _ from 'lodash'

const ResourcesPage = props => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicHomepage {
        nodes {
          lang
          data {
            body {
              ... on PrismicHomepageBodyNavigationBar {
                id
                slice_type
              }
              ... on PrismicHomepageBodyHero {
                id
                slice_type
              }
              ... on PrismicHomepageBodySteps {
                id
                slice_type
              }
              ... on PrismicHomepageBodyHighlight {
                id
                slice_type
              }
              ... on PrismicHomepageBodyLinks {
                id
                slice_type
              }
              ... on PrismicHomepageBodyFooter {
                id
                slice_type
              }
            }
          }
        }
      }
      allPrismicResource(
        sort: {
          fields: [first_publication_date]
          order: ASC
        }
      ) {
        edges {
          node {
            data {
              content {
                text
              }
              title {
                text
              }
              feature_image {
                alt
                url
              }
            }
            lang
            uid
          }
        }
      }
    }
  `)
  const locale = props.pageContext.locale || 'en-us'
  return (
    <Layout locale={locale} pageContext={props.pageContext}
            navBarId={data.allPrismicHomepage.nodes.find(cur => cur.lang === locale).data.body.find(cur => cur.slice_type === 'navigation_bar').id}
            footerId={data.allPrismicHomepage.nodes.find(cur => cur.lang === locale).data.body.find(cur => cur.slice_type === 'footer').id}>
      <SEO/>
      <section className='hero is-primary'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-centered'>
              <div className='column is-three-quarters'>
                <h3 className='title is-spaced has-text-centered'>Resources</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <div className='columns is-centered'>
            <div className='column is-three-quarters'>
              {_.chunk(data.allPrismicResource.edges.filter(cur => cur.node.lang === locale), 2).map((row) => (
                <div className="tile is-ancestor">
                  {_.map(row, (cur, idx) => (
                    <div key={idx} className="tile is-parent is-6 is-clickable">
                      <div className="tile is-child box">
                        <figure className="image is-3by1">
                          <img className="is-rounded" src={cur.node.data.feature_image.url} />
                        </figure>
                        <h1 className="title is-4">{cur.node.data.title.text}</h1>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ResourcesPage
